<template>
  <div class="flex text-gray-800">
    <div class="w-1/3 flex-none h-screen grid content-center justify-center bg-gray-100">
      <img src="/img/logo-login.svg" alt="Logo nomad" class="h-20" />
    </div>
    <div class="flex-1 h-screen mx-auto max-w-[448px] grid content-center">
      <slot />
    </div>
  </div>
</template>

<script setup></script>
